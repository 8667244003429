<template>
  <div v-loading="!dataLoaded" style="margin: 10px; width: calc(100vw - 85px)">
    <geofence-form
      :visible="isOpenGeofenceForm"
      :geofence-color="geofenceColor"
      :geofence-description="geofenceDescription"
      :geofence-icon="geofenceIcon"
      :geofence-name="geofenceName"
      :geofence-speed-limit="geofenceSpeedLimit"
      :selected-geofence="selectedGeofence"
      :geofence-distance="geofenceDistance"
      :geofence-groups="geofenceGroups"
      @close="isOpenGeofenceForm = false"
    />
    <el-table
      v-if="dataLoaded"
      v-el-table-infinite-scroll="load"
      height="calc(100vh - 100px)"
      :data="filteredGeofences.slice(0, count)"
      :row-style="tableRowStyle"
      :header-cell-style="tableHeaderStyle"
      @row-dblclick="handleDoubleClick"
    >
      <el-table-column label="" width="60">
        <template slot-scope="scope">
          <i v-if="!(getType(scope.row)==='poi')" :style="'color: '+(scope.row.attributes.color ? scope.row.attributes.color : '#3232b4')" :class="geofenceImageType(scope.row)"></i>
          <img v-if="getType(scope.row)==='poi'" :src="scope.row.attributes.img" alt="" rel="preload">
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('settings.geofence_name')"
        prop="name"
        sortable
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.geofence_description')"
        prop="description"
        sortable
      >
      </el-table-column>
      <el-table-column
        :label="$t('settings.geofence_speed_Limit')"
        :formatter="speedLimitRenderer"
        prop="attributes.speedLimit"
        sortable
      >
      </el-table-column>
      <el-table-column align="right" label="">
        <template slot="header" style="padding: 5px">
          <el-link href="/traccar-helper/" target="_blank">Importar</el-link><span style="padding: 5px"></span>
          <el-button size="mini" :loading="downloadLoading" icon="el-icon-document" type="primary" @click="handleDownload">Excel</el-button>
        </template>
        <template slot-scope="scope">
          <el-tooltip :content="$t('settings.delete')" placement="top">
            <el-button
              class="tableButton"
              size="small"
              type="danger"
              @click="handleDelete(scope.row)"
            ><i class="fas fa-trash-alt"></i></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('settings.edit')" placement="top">
            <el-button
              size="small"
              class="tableButton"
              @click="handleEdit(scope.row)"
            ><i class="fas fa-edit"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total"
      :total="filteredGeofences.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { traccar } from '@/api/traccar-api'
import * as lnglat from '../../../utils/lnglat'
import { vm } from '@/main'
import image from '../../../icons/pois/airport-blue.svg'
import GeofenceForm from '@/components/GeofenceForm.vue'
import { getGeofenceGroups } from '@/utils/group'

export default {
  name: 'Geofences',
  components: { GeofenceForm },
  data() {
    return {
      downloadLoading: false,
      count: 20,
      isOpenGeofenceForm: false,
      isNewGeofence: true,
      selectedGeofence: null,
      geofenceName: '',
      geofenceDescription: '',
      geofenceSpeedLimit: 0,
      geofenceColor: '',
      geofenceIcon: '',
      geofenceFill: false,
      geofenceDistance: 100,
      applyDistanceToAll: false,
      applyColorToAll: false,
      geofenceGroups: [],
      image: image
    }
  },
  computed: {
    ...mapGetters(['dataLoaded', 'geofences', 'search', 'loading', 'markerTypes', 'groups']),
    areaGeofences: function() {
      return this.geofences.filter(g => g.area.startsWith('POLYGON'))
    },
    pois: function() {
      return this.geofences.filter(g => g.area.startsWith('CIRCLE'))
    },
    lineGeofences: function() {
      return this.geofences.filter(g => g.area.startsWith('LINESTRING'))
    },
    filteredGeofences() {
      return this.geofences.filter(data => !this.search ||
        data.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  methods: {
    handleDownload() {
      this.downloadLoading = true
      import('../../../utils/ExportExcel').then(excel => {
        const tHeader = [
          this.$t('settings.geofence_name'),
          this.$t('settings.groups'),
          this.$t('settings.geofence_description'),
          this.$t('settings.geofence_coordinates')
        ]
        const data = this.filteredGeofences.map(v => {
          const group = this.groups.find(g => g.geofences.geofences.includes(v.id) ||
            g.geofences.pois.includes(v.id) ||
            g.geofences.linegeofences.includes(v.id))
          return [
            v.name,
            group ? group.name : '',
            v.description, v.area && v.area.slice(0, 15000)]
        }).sort((a, b) => a[0].localeCompare(b[0]))
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '',
          autoWidth: false,
          bookType: 'xlsx'
        })
        this.downloadLoading = false
      })
    },
    speedLimitRenderer(row, column, speedLimit) {
      if (this.getType(row) === 'poi') {
        return '-'
      }

      if (!speedLimit || speedLimit === '0') {
        return ''
      }

      return Math.round(speedLimit * 1.852)
    },
    load() {
      this.count += 20
    },
    getImageSrc(imgType) {
      return './img/icons/pois/' + imgType + '-blue.svg'
    },
    handleDelete(row) {
      this.$confirm(this.$t('geofence.' + this.getType(row) + '_delete_info') +
        row.name + '?', this.$t('geofence.' + this.getType(row) + '_delete_title'), {
        confirmButtonText: this.$t('geofence.geofence_edit_confirm'),
        cancelButtonText: this.$t('geofence.geofence_edit_cancel')
      }).then(() => {
        traccar.deleteGeofence(row.id, this.geofenceDeleted)
      }).catch(() => {
      })
    },
    geofenceDeleted(geofenceId) {
      const geofence = vm.$store.state.user.geofences.find((e) => e && e.id !== geofenceId)
      vm.$store.state.user.geofences = vm.$store.state.user.geofences.filter((e) => e && e.id !== geofenceId)
      lnglat.refreshGeofences()
      this.$message({
        message: this.$t('geofence.' + this.getType(geofence) + '_deleted'),
        type: 'success',
        duration: 5 * 1000
      })
    },
    handleEdit(row) {
      this.isNewGeofence = false
      this.selectedGeofence = row
      this.geofenceName = row.name
      this.geofenceDescription = row.description
      this.geofenceSpeedLimit = row.attributes.speedLimit ? Math.round(row.attributes.speedLimit * 1.85200) : 0
      this.geofenceIcon = row.attributes.icon ? row.attributes.icon : 'marker'
      this.geofenceColor = row.attributes.color ? row.attributes.color : '#3232b4'
      this.geofenceFill = row.attributes.fill != null ? row.attributes.fill : true
      this.geofenceDistance = this.getType(this.selectedGeofence) === 'poi' ? this.getDistance(this.selectedGeofence) : 0
      this.geofenceGroups = getGeofenceGroups(row, this.groups).map(g => g.id)
      this.isOpenGeofenceForm = !this.isOpenGeofenceForm
    },
    handleDoubleClick(row) {
      this.handleEdit(row)
    },
    getType(row) {
      return row.area.startsWith('POLYGON') ? 'geofence' : (row.area.startsWith('LINESTRING') ? 'linegeofence' : 'poi')
    },
    getDistance(row) {
      return row.area.slice(0, row.area.length - 1).split(',')[1].trim()
    },
    geofenceImageType(row) {
      return row.area.startsWith('CIRCLE') ? 'fas fa-map-marker-alt' : (row.area.startsWith('LINE') ? 'iconGeofence fas fa-wave-square' : 'iconGeofence fas fa-draw-polygon')
    },
    tableRowStyle() {
      return 'font-size: 14px'
    },
    tableHeaderStyle() {
      return 'font-size: 14px'
    },
    doNothing(scope) {
      /* this method is here because we need the attribute 'slot-scope = "scope"' on the template
       for search box to work, but to be able to commit the variable 'scope' it must be used*/
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../../styles/element-variables.scss';
  .form-item-block {
    width: 100%;
    display: table;
    margin-bottom: 10px
  }
  .formButton {
    float: right;
    margin-top: 5px;
    margin-right: 10px;
  }
  .tableButton {
    float: right;
    margin-right: 10px;
  }
  .modal {
    width: 650px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px 3px;
    transition: all 0.2s ease-in;
  }
  .fadeIn-enter .modal,
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #00000094;
    z-index: 999;
    transition: opacity 0.2s ease;
  }

  .el-table .tomobile td:last-child {
    font-size: 12px
  }

  .el-form-item {
    margin-bottom: 5px
  }

  .alertSelectButton {
    float: left;
    margin-left: 10px;
    height: 40px
  }

  .form-item-block {
    width: 100%;
    display: table;
    margin-bottom: 5px
  }

  .form-item-row {
    display: table-row;
  }

  .form-item-block-left{
    display: table-cell;
    width: 50px;
    padding-right: 10px;
    padding-bottom: 5px;
  }
  .form-item-block-right{
    width: 400px;
    display: table-cell;
    padding-bottom: 5px;
  }
  .iconGeofence {
    padding-left:6px
  }
</style>

